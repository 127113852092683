<template>
  <indicadoresForm
    nombreModal="modal-create"
    title="Crear indicador"
    :indicador.sync="data"
    @processForm="agregar"
  />
</template>

<script>

// import store from '@/store/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import indicadoresForm from './IndicadoresForm.vue'

export default {
  components: {
    indicadoresForm,
  },
  data() {
    return {
      data: {
        nombre: '',
      },
    }
  },
  methods: {
    agregar(indicador) {
      this.$bvModal.hide('modal-create')
      this.$emit('processCreate', indicador.nombre)
    },
  },
}
</script>
