<template>
  <div v-if="selectedObjetivo">
    <b-overlay
      :show="spinner"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <indicadoresForm
        :nombreModal="modal"
        title="Editar indicador"
        :indicador="data.item"
        @processForm="editar"
      />
    </b-overlay>
  </div>
</template>

<script>

import { BOverlay } from 'bootstrap-vue'
import { mapState } from 'vuex'

import indicadoresForm from './IndicadoresForm.vue'

export default {
  components: {
    indicadoresForm,
    BOverlay,
  },
  data() {
    return {
      spinner: false,
    }
  },
  computed: {
    ...mapState('objetivos', ['selectedObjetivo']),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modal: {
      type: String,
      required: true,
    },
  },
  methods: {
    editar(indicador) {
      this.$bvModal.hide(this.modal)
      this.$emit('processUpdate', indicador)
    },
  },
}
</script>
