var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"mb-0",attrs:{"aria-hidden":"true"}},[_c('b-row',[(_vm.processType === 'created')?_c('b-col',{attrs:{"cols":"12","md":_vm.processType === 'created'
        ? '3'
        : '4'}},[_c('b-form-group',{attrs:{"label":"Tipo Enseñanza","label-for":"tipoEnseñanza"}},[_c('b-overlay',[_c('v-select',{attrs:{"placeholder":"Selecciona un tipo enseñanza...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.tipoEnseñanzasOptions,"getOptionLabel":function (option) { return option.title; },"reduce":function (option) { return option.idNivel; },"state":!_vm.$v.objetivo.idNivel.$invalid},on:{"input":_vm.changeTipoEnsenanza},model:{value:(_vm.objetivo.idNivel),callback:function ($$v) {_vm.$set(_vm.objetivo, "idNivel", $$v)},expression:"objetivo.idNivel"}}),(_vm.$v.objetivo.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"idNivelInfo"}},[_vm._v(" El campo tipo enseñanza "),(!_vm.$v.objetivo.idNivel.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e()]):_vm._e()],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":_vm.processType === 'created'
        ? '3'
        : '4'}},[_c('b-form-group',{attrs:{"label":"Grado","label-for":"grado"}},[_c('b-overlay',{attrs:{"show":_vm.cargaGrados}},[_c('v-select',{attrs:{"placeholder":"Selecciona un grado...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","getOptionLabel":function (option) { return option.title; },"reduce":function (option) { return option.id; },"options":_vm.gradosOptions,"disabled":_vm.disabledOption},on:{"input":_vm.changeGrado},model:{value:(_vm.objetivo.idGrado),callback:function ($$v) {_vm.$set(_vm.objetivo, "idGrado", $$v)},expression:"objetivo.idGrado"}}),(_vm.$v.objetivo.idGrado.$invalid
              && _vm.$v.objetivo.idGrado.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"idGradoInfo"}},[_vm._v(" El campo grado "),(!_vm.$v.objetivo.idGrado.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e()]):_vm._e()],1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":_vm.processType === 'created'
        ? '3'
        : '4'}},[_c('b-form-group',{attrs:{"label":"Asignatura","label-for":"asignatura"}},[_c('v-select',{attrs:{"placeholder":"Selecciona una asignatura...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","getOptionLabel":function (option) { return option.title; },"reduce":function (option) { return option.id; },"options":_vm.asignaturasOptions,"disabled":_vm.disabledOption},on:{"input":_vm.changeAsignatura},model:{value:(_vm.objetivo.idAsignatura),callback:function ($$v) {_vm.$set(_vm.objetivo, "idAsignatura", $$v)},expression:"objetivo.idAsignatura"}}),(_vm.$v.objetivo.idAsignatura.$invalid
            && _vm.$v.objetivo.idAsignatura.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"idAsignaturaInfo"}},[_vm._v(" El campo asignatura "),(!_vm.$v.objetivo.idAsignatura.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":_vm.processType === 'created'
        ? '3'
        : '4'}},[_c('b-form-group',{attrs:{"label":"Eje","label-for":"eje"}},[_c('v-select',{attrs:{"placeholder":"Selecciona un eje...","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","getOptionLabel":function (option) { return option.title; },"reduce":function (option) { return option.id; },"options":_vm.ejesOptions,"disabled":_vm.disabledOption},model:{value:(_vm.objetivo.idEje),callback:function ($$v) {_vm.$set(_vm.objetivo, "idEje", $$v)},expression:"objetivo.idEje"}}),(_vm.$v.objetivo.idEje.$invalid
            && _vm.$v.objetivo.idEje.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"idEjeInfo"}},[_vm._v(" El campo eje "),(!_vm.$v.objetivo.idEje.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"label":"Abreviatura *","label-for":"abreviatura"}},[_c('b-form-input',{attrs:{"id":"abreviatura","placeholder":"Ej: OA 01","state":_vm.$v.objetivo.abreviatura.$invalid && _vm.$v.objetivo.abreviatura.$dirty
            ? false
            : null},model:{value:(_vm.objetivo.abreviatura),callback:function ($$v) {_vm.$set(_vm.objetivo, "abreviatura", $$v)},expression:"objetivo.abreviatura"}}),(_vm.$v.objetivo.abreviatura.$invalid && _vm.$v.objetivo.abreviatura.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"abreviaturasInfo"}},[_vm._v(" El campo abreviatura "),(!_vm.$v.objetivo.abreviatura.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e(),(!_vm.$v.objetivo.abreviatura.maxLength)?_c('span',[_vm._v(" debe tener máximo 5 caracteres. ")]):_vm._e()]):_vm._e()],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"label":"Priorización Interna","label-for":"priorizacion"}},[_c('b-form-checkbox',{staticClass:"custom-control-success text-left mt-75",attrs:{"checked":"1","name":"check-button","switch":""},model:{value:(_vm.objetivo.priorizacion),callback:function ($$v) {_vm.$set(_vm.objetivo, "priorizacion", $$v)},expression:"objetivo.priorizacion"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"StarIcon"}})],1)])],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"label":"Autorización","label-for":"autorizacion"}},[_c('b-form-checkbox',{staticClass:"custom-control-success text-left mt-75",attrs:{"checked":"","name":"check-button","switch":""},model:{value:(_vm.objetivo.autorizacion),callback:function ($$v) {_vm.$set(_vm.objetivo, "autorizacion", $$v)},expression:"objetivo.autorizacion"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1)])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre *","label-for":"nombreObjetivo"}},[_c('b-form-textarea',{attrs:{"id":"nombreObjetivo","rows":"7","placeholder":"Ingresa el o los nombreObjetivo","state":_vm.$v.objetivo.nombreObjetivo.$invalid && _vm.$v.objetivo.nombreObjetivo.$dirty
            ? false
            : null},model:{value:(_vm.objetivo.nombreObjetivo),callback:function ($$v) {_vm.$set(_vm.objetivo, "nombreObjetivo", $$v)},expression:"objetivo.nombreObjetivo"}}),(_vm.$v.objetivo.nombreObjetivo.$invalid && _vm.$v.objetivo.nombreObjetivo.$dirty)?_c('b-form-invalid-feedback',{attrs:{"id":"nombreObjetivoInfo"}},[_vm._v(" El campo nombreObjetivo "),(!_vm.$v.objetivo.nombreObjetivo.required)?_c('span',[_vm._v(" es requerido. ")]):_vm._e()]):_vm._e()],1)],1)],1),_c('colLinea',{staticClass:"mt-1 mb-0"}),_c('div',{staticClass:"d-flex mb-0 mt-3"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" Indicadores ")])]),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('indicadores',{attrs:{"processType":_vm.processType},on:{"addIndicadores":_vm.addIndicadores}})],1)],1),_c('colLinea',{staticClass:"mt-2 mb-0"}),_c('btnSubmit',{attrs:{"variant":"primary","btnText":_vm.btnSubmit},on:{"processBtn":_vm.submitOption}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }