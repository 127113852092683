<template>
  <b-button-group>

    <!-- v-if="$can('create', modulo)" -->
    <template
      v-if="clone && $can('create', modulo)"
    >
      <b-button
        v-if="modalClone"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal="modalClone"
        variant="secondary"
        class="btn-sm btn-icon mr-25"
        :title="'Duplicar '+ title"
      >
        <feather-icon
          icon="CopyIcon"
        />
      </b-button>
      <b-button
        v-else
        variant="secondary"
        class="btn-sm btn-icon mr-25"
        :title="'Duplicar '+ title"
        @click="$emit('processGoToClone', data.item)"
      >
        <feather-icon
          icon="Edit3Icon"
        />
      </b-button>
    </template>

    <!-- EDITAR en MODAL -->
    <!-- v-if="$can('update', modulo)" -->
    <template
      v-if="$can('update', modulo)"
    >
      <b-button
        v-if="modal !== 'false'"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        v-b-modal="modal"
        variant="secondary"
        class="btn-sm btn-icon mr-25"
        :title="'Editar' + title"
      >
        <feather-icon
          icon="Edit3Icon"
        />
      </b-button>

      <b-button
        v-else
        variant="secondary"
        class="btn-sm btn-icon mr-25"
        :title="'Editar '+ title"
        @click="$emit('processGoToUpdate', data.item)"
      >
        <feather-icon
          icon="Edit3Icon"
        />
      </b-button>
    </template>

    <!-- ELIMINAR -->
    <b-button
      v-if="$can('delete', modulo)"
      variant="secondary"
      class="btn-sm btn-icon"
      @click="!estado ? '' : $emit('processRemove', data.item.id)"
      :title="!estado ? moduloCreado + ': ' + cantidad : 'Eliminar '+ title "
      :disabled="!estado"
    >
      <feather-icon
        icon="Trash2Icon"
      />
    </b-button>

  </b-button-group>
</template>

<script>
import {
  BButton, BButtonGroup, VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BButtonGroup,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    modulo: {
      type: String,
      required: false,
    },
    estado: {
      type: Boolean,
      default: true,
    },
    cantidad: {
      type: Number,
      default: 0,
    },
    modal: {
      type: String,
      default: 'false',
    },
    title: {
      type: String,
      default: '',
    },
    clone: {
      type: String,
      default: '',
    },
    modalClone: {
      type: String,
      default: 'false',
    },
  },
  data() {
    return {
      moduloCreado: '',
    }
  },
  mounted() {
    // switch (this.modulo) {
    //   case 'ubicaciones':
    //     this.moduloCreado = 'producto(s)'
    //     break

    //   case 'categorias':
    //     this.moduloCreado = 'producto(s)'
    //     break

    //   case 'clientes':
    //     this.moduloCreado = 'salidas'
    //     break

    //   default:
    //     break
    // }
  },
}
</script>
