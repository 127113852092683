<template>
  <b-card
    aria-hidden="true"
    class="mb-0"
  >
    <!-- Header: Personal Info -->
    <!-- <div class="d-flex mb-2 mt-0">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Información Personal
      </h4>
    </div> -->

    <!-- Sistema Info: Input Fields -->
    <b-row>

      <!-- Field: TipoEnseñanza -->
      <b-col
        v-if="processType === 'created'"
        cols="12"
        :md="processType === 'created'
          ? '3'
          : '4'"
      >
        <b-form-group
          label="Tipo Enseñanza"
          label-for="tipoEnseñanza"
        >
          <b-overlay>
            <v-select
              v-model="objetivo.idNivel"
              placeholder="Selecciona un tipo enseñanza..."
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="tipoEnseñanzasOptions"
              :getOptionLabel="option => option.title"
              :reduce="option => option.idNivel"
              :state="!$v.objetivo.idNivel.$invalid"
              @input="changeTipoEnsenanza"
            />
            <b-form-invalid-feedback
              v-if="$v.objetivo.$dirty"
              id="idNivelInfo"
            >
              El campo tipo enseñanza
              <span v-if="!$v.objetivo.idNivel.required">
                es requerido.
              </span>
            </b-form-invalid-feedback>
          </b-overlay>
        </b-form-group>
      </b-col>

      <!-- Field: Grado -->
      <b-col
        cols="12"
        :md="processType === 'created'
          ? '3'
          : '4'"
      >
        <b-form-group
          label="Grado"
          label-for="grado"
        >
          <b-overlay
            :show="cargaGrados"
          >
            <v-select
              v-model="objetivo.idGrado"
              placeholder="Selecciona un grado..."
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :getOptionLabel="option => option.title"
              :reduce="option => option.id"
              :options="gradosOptions"
              :disabled="disabledOption"
              @input="changeGrado"
            />
            <b-form-invalid-feedback
              v-if="$v.objetivo.idGrado.$invalid
                && $v.objetivo.idGrado.$dirty"
              id="idGradoInfo"
            >
              El campo grado
              <span v-if="!$v.objetivo.idGrado.required">
                es requerido.
              </span>
            </b-form-invalid-feedback>
          </b-overlay>
        </b-form-group>
      </b-col>

      <!-- Field: Asignatura -->
      <b-col
        cols="12"
        :md="processType === 'created'
          ? '3'
          : '4'"
      >
        <b-form-group
          label="Asignatura"
          label-for="asignatura"
        >
          <v-select
            v-model="objetivo.idAsignatura"
            placeholder="Selecciona una asignatura..."
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :getOptionLabel="option => option.title"
            :reduce="option => option.id"
            :options="asignaturasOptions"
            :disabled="disabledOption"
            @input="changeAsignatura"
          />
          <b-form-invalid-feedback
            v-if="$v.objetivo.idAsignatura.$invalid
              && $v.objetivo.idAsignatura.$dirty"
            id="idAsignaturaInfo"
          >
            El campo asignatura
            <span v-if="!$v.objetivo.idAsignatura.required">
              es requerido.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- Field: Eje -->
      <b-col
        cols="12"
        :md="processType === 'created'
          ? '3'
          : '4'"
      >
        <b-form-group
          label="Eje"
          label-for="eje"
        >
          <v-select
            v-model="objetivo.idEje"
            placeholder="Selecciona un eje..."
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :getOptionLabel="option => option.title"
            :reduce="option => option.id"
            :options="ejesOptions"
            :disabled="disabledOption"
          />
          <b-form-invalid-feedback
            v-if="$v.objetivo.idEje.$invalid
              && $v.objetivo.idEje.$dirty"
            id="idEjeInfo"
          >
            El campo eje
            <span v-if="!$v.objetivo.idEje.required">
              es requerido.
            </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- Field: ABREVIATURA -->
      <b-col
        cols="12"
        md="2"
        class="mt-1"
      >
        <b-form-group
          label="Abreviatura *"
          label-for="abreviatura"
        >
          <b-form-input
            id="abreviatura"
            v-model="objetivo.abreviatura"
            placeholder="Ej: OA 01"
            :state="$v.objetivo.abreviatura.$invalid && $v.objetivo.abreviatura.$dirty
              ? false
              : null"
          />
          <b-form-invalid-feedback
            v-if="$v.objetivo.abreviatura.$invalid && $v.objetivo.abreviatura.$dirty"
            id="abreviaturasInfo"
          >
            El campo abreviatura
            <span v-if="!$v.objetivo.abreviatura.required">
              es requerido.
            </span>
            <span v-if="!$v.objetivo.abreviatura.maxLength">
              debe tener máximo 5 caracteres.
            </span>
          </b-form-invalid-feedback>

        </b-form-group>
      </b-col>

      <!-- PRIORIZACION INTERNA -->
      <b-col
        cols="12"
        md="2"
        class="mt-1"
      >
        <b-form-group
          label="Priorización Interna"
          label-for="priorizacion"
        >
          <b-form-checkbox
            checked="1"
            v-model="objetivo.priorizacion"
            class="custom-control-success text-left mt-75"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="StarIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <!-- AUTORIZACION -->
      <b-col
        cols="12"
        md="2"
        class="mt-1"
      >
        <b-form-group
          label="Autorización"
          label-for="autorizacion"
        >
          <b-form-checkbox
            checked=""
            v-model="objetivo.autorizacion"
            class="custom-control-success text-left mt-75"
            name="check-button"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <!-- Field: Nombre -->
      <b-col
        cols="12"
        md="12"
      >
        <b-form-group
          label="Nombre *"
          label-for="nombreObjetivo"
        >
          <b-form-textarea
            id="nombreObjetivo"
            v-model="objetivo.nombreObjetivo"
            rows="7"
            placeholder="Ingresa el o los nombreObjetivo"
            :state="$v.objetivo.nombreObjetivo.$invalid && $v.objetivo.nombreObjetivo.$dirty
              ? false
              : null"
          />
          <b-form-invalid-feedback
            v-if="$v.objetivo.nombreObjetivo.$invalid && $v.objetivo.nombreObjetivo.$dirty"
            id="nombreObjetivoInfo"
          >
            El campo nombreObjetivo
            <span v-if="!$v.objetivo.nombreObjetivo.required">
              es requerido.
            </span>
            <!-- <span v-if="!$v.objetivo.nombreObjetivo.maxLength">
              debe tener máximo 3000 caracteres.
            </span> -->
          </b-form-invalid-feedback>

        </b-form-group>
      </b-col>

    </b-row>

    <colLinea
      class="mt-1 mb-0"
    />
    <!-- Header: INDICADORES -->
    <div class="d-flex mb-0 mt-3">
      <!-- <feather-icon
        icon="UnlockIcon"
        size="19"
      /> -->
      <h4 class="mb-0">
        Indicadores
      </h4>
    </div>

    <b-row>
      <!-- Field: Roles -->
      <b-col
        cols="12"
        md="12"
      >
        <indicadores
          :processType="processType"
          @addIndicadores="addIndicadores"
        />
      </b-col>

    </b-row>

    <!-- <template
      v-if="disabledCursosAsignaturas"
    >
      <div class="d-flex mb-1 mt-3">
        <feather-icon
          icon="BookIcon"
          size="19"
        />
        <h4 class="mb-0 ml-50">
          Asignaturas por Curso
        </h4>
      </div>

      <b-row>
      </b-row>
    </template> -->

    <colLinea
      class="mt-2 mb-0"
    />

    <!-- Action Buttons -->
    <!-- v-if="cargando" -->
    <btnSubmit
      variant="primary"
      :btnText="btnSubmit"
      @processBtn="submitOption"
    />

  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback,
  BCard, BOverlay, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { validationMixin } from 'vuelidate'
import {
  required, maxLength,
} from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

// COMPONENTES RECICLADOS
import btnSubmit from '../../components/Form/btnSubmit.vue'
import colLinea from '../../components/Form/colLinea.vue'

// COMPONENTES HIJOS
import Indicadores from './Indicadores.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BCard,
    BOverlay,
    BFormTextarea,
    BFormCheckbox,
    vSelect,

    // COMPONENTES RECICLADOS
    btnSubmit,
    colLinea,

    // COMPONENTES HIJOS
    Indicadores,
  },
  mixins: [validationMixin],
  props: {
    objetivo: {
      type: Object,
      required: true,
    },
    btnSubmit: {
      type: String, // Texto del boton
      default: 'Guardar Objetivo',
    },
    processType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dir: 'ltr',
      tipoEnseñanzasOptions: [],
      gradosOptions: [],
      asignaturasOptions: [],
      ejesOptions: [],
      cargaGrados: false,
      cargaAsignaturas: false,
      cargaEjes: false,
      disabledOption: true,
    }
  },
  validations() {
    return {
      objetivo: {
        idNivel: {
          required,
        },
        idGrado: {
          required,
        },
        idAsignatura: {
          required,
        },
        idEje: {
          required,
        },
        abreviatura: {
          required,
          maxLength: maxLength(5),
        },
        nombreObjetivo: {
          required,
          // maxLength: maxLength(3000),
        },
        estado: {},
      },
    }
  },
  computed: {
    ...mapGetters({
      getTipoEnseñanzas: 'tipoEnseñanza/getTipoEnseñanzas',
      getGrados: 'grados/getGrados',
      getAsignaturasActivosGrado: 'asignaturas/getAsignaturasActivosGrado',
      getEjesAsignatura: 'ejes/getEjesAsignatura',
      user: 'auth/user',
    }),
    disabledEstablecimiento() {
      return this.setDisabledEstablecimiento()
    },
    disabledCursosAsignaturas() {
      return this.setDisabledEnrolamiento()
    },
  },
  watch: {
    getTipoEnseñanzas() {
      this.setTipoEnseñanzasOptions(this.getTipoEnseñanzas)
    },
  },
  mounted() {
    if (this.processType !== 'created') {
      this.setOptions()
      this.disabledOptions()
    } else {
      this.fetchTipoEnseñanza()
      this.disabledOptions()
    }
  },
  methods: {
    ...mapActions({
      attempt: 'auth/attempt',
      fetchTipoEnseñanza: 'tipoEnseñanza/fetchTipoEnseñanza',
      fetchGrados: 'grados/fetchGrados',
      fetchAsignaturasActivosGrado: 'asignaturas/fetchAsignaturasActivosGrado',
      fetchEjesAsignaturaDistinct: 'ejes/fetchEjesAsignaturaDistinct',
    }),
    disabledOptions() {
      if (this.processType === 'created') {
        this.disabledOption = false
      }
    },
    setOptions() {
      this.setPriorizacion()
      this.setAutorizacion()
      this.fetchTipoEnseñanza().then(() => {
        const { idNivel } = this.objetivo
        const tipoEnseñanzas = this.getTipoEnseñanzas.find(gte => gte.id === idNivel)
        if (typeof tipoEnseñanzas !== 'undefined') {
          this.fetchGrados().then(() => {
            const grados = this.getGrados.filter(gg => gg.idNivel === tipoEnseñanzas.idNivel)
            this.setGradosOptions(grados)
            const { idGrado } = this.objetivo
            this.cargaAsignaturas = true
            this.fetchAsignaturasActivosGrado(idGrado).then(() => {
              this.setAsignaturasOptions(this.getAsignaturasActivosGrado)
              const { idAsignatura } = this.objetivo
              this.cargaAsignaturas = true
              this.fetchEjesAsignaturaDistinct(idAsignatura).then(() => {
                this.setEjesOptions(this.getEjesAsignatura)
              })
            })
          })
        }
      })
    },
    setPriorizacion() {
      if (this.processType === 'clone') {
        this.objetivo.priorizacion = this.objetivo.priorizacionInterna === 1
      } else if (this.processType === 'update') {
        this.objetivo.priorizacion = this.objetivo.priorizacionInterna === 1
      }
    },
    setAutorizacion() {
      this.objetivo.autorizacion = this.objetivo.estado === 'Activo'
    },
    setTipoEnseñanzasOptions(tipoEnseñanzas) {
      this.tipoEnseñanzasOptions = []
      tipoEnseñanzas.forEach(tipoEnseñanza => {
        const title = `${tipoEnseñanza.idNivel} - ${tipoEnseñanza.nombre}`
        this.tipoEnseñanzasOptions.push({
          id: tipoEnseñanza.id,
          idNivel: tipoEnseñanza.idNivel,
          title,
        })
      })
      this.cargaTipoEnseñanzas = false
    },
    changeTipoEnsenanza() {
      this.resetGradosOptions()
      this.resetAsignaturasOptions()
      this.resetEjesOptions()
      const { idNivel } = this.objetivo
      if (idNivel) {
        this.cargaGrados = true
        this.fetchGrados().then(() => {
          const grados = this.getGrados.filter(gg => gg.idNivel === idNivel)
          this.setGradosOptions(grados)
        })
      }
    },
    setGradosOptions(grados) {
      grados.forEach(grado => {
        const title = `${grado.nombre}`
        this.gradosOptions.push({
          id: grado.id,
          idGrado: grado.idGrado,
          title,
        })
      })
      this.cargaGrados = false
    },
    changeGrado() {
      this.resetAsignaturasOptions()
      const { idGrado } = this.objetivo
      if (idGrado) {
        this.cargaAsignaturas = true
        this.fetchAsignaturasActivosGrado(idGrado).then(() => {
          this.setAsignaturasOptions(this.getAsignaturasActivosGrado)
        })
      }
    },
    setAsignaturasOptions(asignaturas) {
      asignaturas.forEach(asignatura => {
        const title = `${asignatura.nombreAsignatura}`
        this.asignaturasOptions.push({
          id: asignatura.id,
          title,
        })
      })
      this.cargaGrados = false
    },
    changeAsignatura() {
      this.resetEjesOptions()
      const { idAsignatura } = this.objetivo
      if (idAsignatura) {
        this.cargaAsignaturas = true
        this.fetchEjesAsignaturaDistinct(idAsignatura).then(() => {
          this.setEjesOptions(this.getEjesAsignatura)
        })
      }
    },
    addIndicadores(indicadores) {
      this.objetivo.indicadores = []
      this.objetivo.indicadores = indicadores
    },
    setEjesOptions(ejes) {
      ejes.forEach(eje => {
        const title = `${eje.nombre}`
        this.ejesOptions.push({
          id: eje.id,
          title,
        })
      })
      this.cargaGrados = false
    },
    resetGradosOptions() {
      this.objetivo.idGrado = null
      this.gradosOptions = [{
        id: null,
        title: 'Selecciona un grado...',
      }]
    },
    resetAsignaturasOptions() {
      this.objetivo.idAsignatura = null
      this.asignaturasOptions = [{
        id: null,
        title: 'Selecciona una asignatura...',
      }]
    },
    resetEjesOptions() {
      this.objetivo.idEje = null
      this.ejesOptions = [{
        id: null,
        title: 'Selecciona un eje...',
      }]
    },
    submitOption() {
      this.$v.objetivo.$touch()
      if (!this.$v.objetivo.$invalid) {
        const estado = this.objetivo.autorizacion
          ? 'Activo'
          : 'Inactivo'
        const priorizacion = this.objetivo.priorizacion === true
          ? 1
          : null

        let data
        if (this.processType === 'clone' || this.processType === 'created') {
          data = {
            nombre: this.objetivo.nombreObjetivo,
            abreviatura: this.objetivo.abreviatura,
            priorizacion,
            idEje: this.objetivo.idEje,
            idEstablecimiento: this.user.idEstablecimientoActivo,
            indicadores: this.objetivo.indicadores,
            estado,
          }
        } else if (this.processType === 'update') {
          data = {
            id: this.objetivo.id,
            nombre: this.objetivo.nombreObjetivo,
            abreviatura: this.objetivo.abreviatura,
            priorizacion,
            idEje: this.objetivo.idEje,
            idEstablecimiento: this.user.idEstablecimientoActivo,
            indicadores: this.objetivo.indicadores,
            estado,
          }
        }

        this.$emit('processForm', data)
      }
    },
    setDisabledEnrolamiento() {
      let retorna = false
      if (this.processType === 'updated') {
        let isDocente = false
        if (typeof this.objetivo.nombreRol !== 'undefined') {
          const { title } = this.objetivo.nombreRol
          if (
            title === 'Docente'
            || title === 'Docente Pie'
            || title === 'Asistente'
          ) {
            isDocente = true
          }
        }
        retorna = this.cursos.length > 0 && isDocente
      } else {
        retorna = this.cursos.length
      }
      return retorna
    },
    esRolAdmin() {
      const { nombreRol } = this.objetivo
      return nombreRol === 'Super Administrador'
        || nombreRol === 'Administrador Daem'
    },
    setRolesOptions(roles) {
      this.rolesOption = []
      if (this.user.rolActivo === 'Super Administrador'
        || this.user.rolActivo === 'Administrador Daem'
      ) {
        roles.forEach(rol => {
          this.rolesOption.push({
            id: rol.id,
            title: rol.name,
          })
        })
      } else {
        roles.forEach(rol => {
          if (rol.name !== 'Super Administrador'
            && rol.name !== 'Administrador Daem'
          ) {
            this.rolesOption.push({
              id: rol.id,
              title: rol.name,
            })
          }
        })
      }
    },
    resetEstablecimientos() {
      this.establecimientoOption = []
      this.objetivo.idEstablecimiento = null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
