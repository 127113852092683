<template>
  <b-modal
    :id="nombreModal"
    :title="title"
    centered
    size="lg"
    cancel-title="Cancelar"
    cancel-variant="outline-secondary"
    ok-title="Guardar Indicador"
    @ok.prevent="submitOption"
  >
    <!-- :ok-disabled="this.v$.indicador.$errors.length > 0" -->
    <b-overlay
      :show="cargando"
      spinner-variant="primary"
      variant="semi-dark"
    >
      <b-form>
        <!-- Reunión Info: Input Fields -->
        <b-row>
          <!-- Field: NOMBRE -->
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Nombre *"
              label-for="nombre"
            >
              <b-form-input
                id="nombre"
                placeholder="Ingresa el indicador"
                v-model="nombreIndicador"
              />
                <!-- :state="v$.indicador.nombre.$error === true
                  ? false
                  : null"
                @blur.native="v$.indicador.nombre.$touch" -->
              <!-- <b-form-invalid-feedback
                v-if="v$.indicador.nombre.$error"
                id="acuerdosInfo"
                class="text-right"
              >
                <p v-for="error of v$.indicador.nombre.$errors" :key="error.$uid">
                  {{ error.$message }}
                </p>
              </b-form-invalid-feedback> -->
            </b-form-group>
          </b-col>

        </b-row>

      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput,
  BOverlay, BModal, BForm,
  // VBModal, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { validationMixin } from 'vuelidate'
// import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BOverlay,
    BModal,
    BForm,
    // VBModal,
  },
  directives: {
    // 'b-modal': VBModal,
    Ripple,
  },
  mixins: [validationMixin],
  props: {
    indicador: {
      type: Object,
      required: true,
    },
    nombreModal: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dir: 'ltr',
      cargando: false,
      nombreIndicador: '',
    }
  },
  computed: {
  },
  watch: {
    indicador(indicador) {
      this.nombreIndicador = ''
      this.nombreIndicador = indicador.nombre
    },
  },
  mounted() {
    if (this.indicador.id) {
      this.nombreIndicador = this.indicador.nombre
    }
  },
  methods: {
    submitOption() {
      if (this.nombreIndicador) {
        const data = {
          id: this.indicador.id,
          nombre: this.nombreIndicador,
        }
        this.$emit('processForm', data)
        if (!this.indicador.id) {
          this.nombreIndicador = ''
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
