<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
  >
    <b-row>
      <b-col
        lg="2"
        md="3"
        sm="3"
        class="my-1"
      >
        <!-- BOTON MOSTRAR -->
        <btnMostrar
          :pageOptions.sync="pageOptions"
          :perPage.sync="perPage"
          :total.sync="items.length"
        />
      </b-col>
      <b-col
        lg="6"
        md="5"
        sm="5"
        class="my-1"
      >
      </b-col>
      <!-- BOTON CREAR -->
      <b-col
        md="4"
        sm="4"
        class="my-1"
      >
        <!-- CREAR -->
        <indicadores-create
          @processCreate="agregarIndicador"
        />
        <!-- BOTON CREAR -->
        <btn-crear-modal
          accion="Crear"
          texto="Indicador"
          modal="modal-create"
          modulo="indicadores"
        />
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          small
          hover
          noCollapse
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :busy="cargando"
          :filter="filter"
          :filter-included-fields="filterOn"
          :tbody-tr-class="rowClass"
          @filtered="onFiltered"
        >

          <!-- Cargando -->
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <spinner />
            </div>
          </template>

          <!-- Column: NOMBRE -->
          <template #cell(nombre)="data">
            {{ data.item.nombre }}
          </template>

          <!-- Column: ACCIONES -->
          <template #cell(acciones)="data">
            <indicadores-update
              :modal.sync="'modal-update-'+data.item.id"
              :data.sync="data"
              @processUpdate="updateIndicador"
            />
            <colAccionesBtnesModal
              :modal.sync="'modal-update-'+data.item.id"
              :data.sync="data"
              modulo="indicadores"
              @processRemove="remove(data.item.id)"
            />
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>

    </b-row>
  </b-overlay>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BOverlay,
} from 'bootstrap-vue'
import {
  mapGetters, mapActions, mapMutations, mapState,
} from 'vuex'

// COMPONENTES RECICLADOS
// import inputFiltro from '../../components/List/inputFiltro.vue'
import btnMostrar from '../../components/List/btnMostrar.vue'
import btnCrearModal from '../../components/List/btnCrearModal.vue'
import colAccionesBtnesModal from '../../components/List/colAccionesBtnesModal.vue'
import spinner from '../../components/spinner.vue'
import indicadoresCreate from './indicadores/IndicadoresCreate.vue'
import indicadoresUpdate from './indicadores/IndicadoresUpdate.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BOverlay,

    // COMPONENTES RECICLADOS
    btnMostrar,
    btnCrearModal,
    colAccionesBtnesModal,
    spinner,

    indicadoresCreate,
    indicadoresUpdate,
  },
  data() {
    return {
      cargando: true,
      spinner: false,
      items: [],

      // TABLA
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'nombre',
          label: 'Nombre',
          sortable: false,
          thStyle: {
            width: '1000px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '150px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getIndicadoresObjetivo: 'indicadores/getIndicadoresObjetivo',
      getIndicadoresPersonalizados: 'indicadores/getIndicadoresPersonalizados',
      user: 'auth/user',
      getGrados: 'grados/getGrados',
      getAsignaturasActivosGrado: 'asignaturas/getAsignaturasActivosGrado',
    }),
    ...mapState('objetivos', ['selectedObjetivo']),
  },
  watch: {
    getIndicadores(val) {
      this.totalRows = val.length
      this.getIndicadores.forEach(element => {
        this.items.push(element)
      })
    },
    items(items) {
      this.$emit('addIndicadores', items)
    },
  },
  props: {
    processType: {
      type: String,
      required: true,
    },
  },
  mounted() {
    if (this.processType === 'clone') {
      this.cargarIndicadores(this.selectedObjetivo)
      this.setTableList()
    } else if (this.processType === 'update') {
      this.cargarIndicadores(this.selectedObjetivo)
      this.setTableList()
    } else if (this.processType === 'created') {
      this.setTableList()
      this.cargando = false
    }
  },
  methods: {
    ...mapActions({
      fetchIndicadoresObjetivo: 'indicadores/fetchIndicadoresObjetivo',
      fetchIndicadoresPersonalizados: 'indicadores/fetchIndicadoresPersonalizados',
      fetchGrados: 'grados/fetchGrados',
      fetchAsignaturasActivosGrado: 'asignaturas/fetchAsignaturasActivosGrado',
    }),
    ...mapMutations('indicadores', ['setIndicador']),

    // PERMISOS
    setTableList() {
      if (this.$can('update', 'indicadores')
        || this.$can('delete', 'indicadores')
      ) {
        this.fieldAcciones.forEach(field => {
          this.fields.push(field)
        })
      }
    },

    // CARGAR
    cargarIndicadores(objetivo) {
      this.items = []
      if (!objetivo.idEstablecimiento) {
        // MINISTERIO
        const data = {
          idObjetivo: objetivo.id,
          tipo: 'Ministerio',
        }
        this.fetchIndicadoresObjetivo(data).then(() => {
          this.getIndicadoresObjetivo.forEach(indicador => {
            this.items.push({
              id: this.items.length + 1,
              nombre: indicador.nombre,
            })
          })
          this.cargando = false
        })
      } else {
        // PERSONALIZASOS
        this.fetchIndicadoresPersonalizados(objetivo.id).then(() => {
          this.getIndicadoresPersonalizados.forEach(indicador => {
            this.items.push({
              id: this.items.length + 1,
              idIndicador: indicador.idIndicador,
              nombre: indicador.nombre,
            })
          })
          this.cargando = false
        })
      }
    },

    // ACCIONES
    agregarIndicador(nombreIndicador) {
      const itemsArray = this.items
      this.items = []
      itemsArray.forEach(item => {
        this.items.push({
          id: this.items.length + 1,
          idIndicador: item.idIndicador,
          nombre: item.nombre,
        })
      })
      this.items.push({
        id: this.items.length + 1,
        nombre: nombreIndicador,
      })
    },
    updateIndicador(indicador) {
      this.items[indicador.id - 1].nombre = indicador.nombre
      const itemsArray = this.items
      this.items = []
      this.items = itemsArray
    },
    remove(id) {
      const itemsArray = this.items.filter(i => i.id !== id)
      this.items = []
      // itemsArray.forEach(item => {
      //   this.items.push({
      //     id: this.items.length + 1,
      //     nombre: item.nombre,
      //   })
      // })
      this.items = itemsArray
    },

    // COLOR OBJETIVOS PERSONALIZADOS
    rowClass(item, type) {
      const colorClass = 'table-success'
      if (!item || type !== 'row') { return }

      // eslint-disable-next-line consistent-return
      if (typeof item.idEstablecimiento !== 'undefined') { return colorClass }
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
